<template>
  <section id="about" class="py-24 px-4 relative overflow-hidden">
    <!-- Background gradient -->
    <div class="absolute -z-10 inset-0 bg-gradient-to-b from-transparent to-gray-900/30"></div>
    
    <div class="max-w-7xl mx-auto">
      <div class="text-center mb-16">
        <h2 class="text-3xl md:text-5xl font-bold text-white mb-6">
          Transform Your Energy Management
        </h2>
        <p class="text-xl text-gray-300 max-w-3xl mx-auto">
          Our platform combines real-time monitoring, predictive analytics, and automated 
          optimization to help your business achieve its sustainability goals while 
          maximizing operational efficiency.
        </p>
      </div>

      <div class="grid md:grid-cols-3 gap-8">
        <!-- Smart Monitoring Card -->
        <div class="card transform hover:-translate-y-2 transition-all duration-300">
          <div class="flex items-center mb-6">
            <div class="p-3 bg-blue-500/10 rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
              </svg>
            </div>
            <h3 class="text-xl font-semibold text-white ml-4">Smart Monitoring</h3>
          </div>
          <p class="text-gray-300">
            Get real-time insights into your energy usage patterns with our advanced 
            monitoring system. Identify inefficiencies and optimize consumption automatically.
          </p>
        </div>

        <!-- Cost Optimization Card -->
        <div class="card transform hover:-translate-y-2 transition-all duration-300">
          <div class="flex items-center mb-6">
            <div class="p-3 bg-emerald-500/10 rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-emerald-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <h3 class="text-xl font-semibold text-white ml-4">Cost Optimization</h3>
          </div>
          <p class="text-gray-300">
            Our AI-driven platform continuously analyzes your energy consumption to 
            identify cost-saving opportunities and optimize your energy investments.
          </p>
        </div>

        <!-- Carbon Reduction Card -->
        <div class="card transform hover:-translate-y-2 transition-all duration-300">
          <div class="flex items-center mb-6">
            <div class="p-3 bg-yellow-500/10 rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-yellow-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
            </div>
            <h3 class="text-xl font-semibold text-white ml-4">Carbon Reduction</h3>
          </div>
          <p class="text-gray-300">
            Track and reduce your carbon footprint with detailed emissions monitoring 
            and automated suggestions for sustainable energy usage.
          </p>
        </div>
      </div>

      <!-- Performance Metrics -->
      <div class="mt-20 grid md:grid-cols-4 gap-8 text-center">
        <div class="performance-stat">
          <div class="text-4xl font-bold text-emerald-500 mb-2">30%</div>
          <p class="text-gray-400">Average Cost Savings</p>
        </div>
        <div class="performance-stat">
          <div class="text-4xl font-bold text-emerald-500 mb-2">24/7</div>
          <p class="text-gray-400">Real-time Monitoring</p>
        </div>
        <div class="performance-stat">
          <div class="text-4xl font-bold text-emerald-500 mb-2">15min</div>
          <p class="text-gray-400">Setup Time</p>
        </div>
        <div class="performance-stat">
          <div class="text-4xl font-bold text-emerald-500 mb-2">99.9%</div>
          <p class="text-gray-400">Uptime Guarantee</p>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.card {
  @apply bg-gray-800/50 border border-gray-700 rounded-xl p-6
         hover:border-emerald-500/50 transition-all duration-300;
  backdrop-filter: blur(8px);
}

.performance-stat {
  @apply p-6 rounded-xl bg-gray-800/30 border border-gray-700
         hover:border-emerald-500/50 transition-all duration-300;
}
</style>