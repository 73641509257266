<template>
  <div id="app" class="bg-[#1A202C] min-h-screen">
    <NavBar />
    <main>
      <HeroSection />
      <AboutSection id="about" />
      <FeaturesSection id="features" />
      <FaqSection id="faq" />
    </main>
    <Footer />
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import HeroSection from './components/HeroSection.vue';
import AboutSection from './components/AboutSection.vue';
import FeaturesSection from './components/FeaturesSection.vue';
import FaqSection from './components/FaqSection.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    HeroSection,
    AboutSection,
    FeaturesSection,
    FaqSection,
    Footer,
  },
  mounted() {
    // Add smooth scrolling behavior
    document.documentElement.style.scrollBehavior = 'smooth';
    
    // Add structured data for SEO
    this.addStructuredData();
  },
  methods: {
    addStructuredData() {
      const structuredData = {
        '@context': 'https://schema.org',
        '@type': 'SoftwareApplication',
        'name': 'CarbonLedger',
        'applicationCategory': 'Energy Management Software',
        'operatingSystem': 'Web-based',
        'description': 'Real-time energy tracking and optimization platform for businesses and organizations',
        'aggregateRating': {
          '@type': 'AggregateRating',
          'ratingValue': '4.8',
          'ratingCount': '127'
        }
      };

      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify(structuredData);
      document.head.appendChild(script);
    }
  }
};
</script>

<style>
:root {
  /* Primary Colors */
  --primary-green: #10B981;
  --primary-dark: #1A202C;
  
  /* Accent Colors */
  --accent-green-light: #34D399;
  --accent-green-dark: #059669;
  
  /* Background Colors */
  --bg-dark: #1A202C;
  --bg-darker: #2D3748;
  --bg-card: #2D3748;
  
  /* Text Colors */
  --text-white: #F9FAFB;
  --text-gray-light: #E2E8F0;
  --text-gray: #A0AEC0;
}

body {
  margin: 0;
  padding: 0;
  background: var(--bg-dark);
  color: var(--text-white);
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

section {
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

/* Add transitions for smooth scrolling */
.scroll-mt {
  scroll-margin-top: 5rem;
}

/* Global button styles */
.btn-primary {
  @apply bg-emerald-600 text-white px-6 py-3 rounded-lg font-semibold 
         hover:bg-emerald-500 transition-all duration-200;
}

.btn-secondary {
  @apply border-2 border-emerald-600 text-emerald-600 px-6 py-3 rounded-lg font-semibold 
         hover:bg-emerald-600/10 transition-all duration-200;
}

/* Global card styles */
.card {
  @apply bg-gray-800/50 border border-gray-700 rounded-xl p-6
         hover:border-emerald-500/50 transition-all duration-300;
  backdrop-filter: blur(8px);
}
</style>