<template>
  <nav class="sticky top-0 z-50 bg-[hsl(222,20%,12%,0.95)] backdrop-blur-sm border-b border-[hsl(160,84%,39%,0.15)]">
    <div class="container mx-auto px-4">
      <div class="flex items-center justify-between h-16">
        <!-- Logo -->
        <div class="flex items-center">
          <a href="/" class="flex items-center gap-2">
            <span class="text-2xl font-bold text-[hsl(160,84%,39%)]">CarbonLedger</span>
          </a>
        </div>

        <!-- Navigation Links -->
        <div class="hidden md:flex items-center space-x-8">
          <a href="#about" class="text-[hsl(220,15%,85%)] hover:text-[hsl(0,0%,100%)] transition-colors">About</a>
          <a href="#features" class="text-[hsl(220,15%,85%)] hover:text-[hsl(0,0%,100%)] transition-colors">Features</a>
          <a href="#faq" class="text-[hsl(220,15%,85%)] hover:text-[hsl(0,0%,100%)] transition-colors">FAQ</a>
        </div>

        <!-- CTA Button -->
        <div class="hidden md:flex items-center">
          <button 
            @click="scrollToEmailForm" 
            class="bg-[hsl(160,84%,39%)] text-white px-4 py-2 rounded-lg 
                   hover:bg-[hsl(160,84%,45%)] active:bg-[hsl(160,84%,35%)] transition-colors"
          >
            Learn More
          </button>
        </div>

        <!-- Mobile menu button -->
        <div class="md:hidden">
          <button 
            @click.stop="toggleMobileMenu" 
            class="text-[hsl(220,15%,85%)] hover:text-[hsl(0,0%,100%)] p-2"
            aria-label="Toggle menu"
          >
            <svg 
              v-if="!isMobileMenuOpen"
              class="h-6 w-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg 
              v-else
              class="h-6 w-6" 
              fill="none" 
              stroke="currentColor" 
              viewBox="0 0 24 24"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu -->
    <transition
      enter-active-class="transition duration-200 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-100 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <div 
        v-if="isMobileMenuOpen" 
        class="md:hidden bg-[hsl(222,20%,12%)] border-t border-[hsl(160,84%,39%,0.15)]"
        @click.stop
      >
        <div class="px-2 pt-2 pb-3 space-y-1">
          <a 
            v-for="link in mobileLinks" 
            :key="link.text"
            :href="link.href"
            class="block px-3 py-2 text-[hsl(220,15%,85%)] hover:text-[hsl(0,0%,100%)] transition-colors"
            @click="isMobileMenuOpen = false"
          >
            {{ link.text }}
          </a>
          <div class="px-3 py-2">
            <button 
              @click="scrollToEmailForm"
              class="block w-full text-center bg-[hsl(160,84%,39%)] text-white px-4 py-2 rounded-lg 
                     hover:bg-[hsl(160,84%,45%)] active:bg-[hsl(160,84%,35%)] transition-colors"
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
import eventBus from '../eventBus';

export default {
  name: 'NavBar',
  data() {
    return {
      isMobileMenuOpen: false,
      mobileLinks: [
        { text: 'About', href: '#about' },
        { text: 'Features', href: '#features' },
        { text: 'FAQ', href: '#faq' },
      ]
    }
  },
  methods: {
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    scrollToEmailForm() {
      this.isMobileMenuOpen = false; // Close mobile menu if open
      // Emit event using event bus
      eventBus.emit('show-email-form');
      // Scroll to hero section
      document.querySelector('#app').scrollIntoView({ behavior: 'smooth' });
    }
  },
  mounted() {
    // Close mobile menu when clicking outside
    document.addEventListener('click', (event) => {
      if (!this.$el.contains(event.target) && this.isMobileMenuOpen) {
        this.isMobileMenuOpen = false;
      }
    });

    // Close mobile menu when screen is resized to desktop
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 768) {
        this.isMobileMenuOpen = false;
      }
    });
  },
  beforeUnmount() {
    // Clean up event listeners
    document.removeEventListener('click', this.handleOutsideClick);
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>