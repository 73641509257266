<template>
  <section id="faq" class="py-24 relative">
    <div class="absolute inset-0 -z-10 bg-gradient-to-b from-transparent to-gray-900/30"></div>
    
    <div class="max-w-4xl mx-auto px-4">
      <div class="text-center mb-16">
        <h2 class="text-3xl md:text-5xl font-bold text-white mb-6">Common Questions</h2>
        <p class="text-xl text-gray-300">Everything you need to know about implementing CarbonLedger</p>
      </div>
      
      <div class="space-y-4">
        <div v-for="(faq, index) in faqs" 
             :key="index" 
             class="bg-gray-800/50 border border-gray-700 rounded-xl overflow-hidden hover:border-emerald-500/50 transition-all duration-300">
          <button 
            class="w-full text-left px-6 py-4 flex items-center justify-between"
            @click="toggleFaq(index)"
          >
            <h3 class="text-lg font-semibold text-white pr-8">{{ faq.question }}</h3>
            <svg 
              class="w-6 h-6 text-emerald-500 transform transition-transform duration-200"
              :class="{ 'rotate-180': faq.isOpen }"
              fill="none" 
              viewBox="0 0 24 24" 
              stroke="currentColor"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>
          
          <div 
            v-show="faq.isOpen" 
            class="px-6 pb-4 text-gray-300"
          >
            <p class="mb-4">{{ faq.answer }}</p>
            <div v-if="faq.details" class="mt-4 pt-4 border-t border-gray-700">
              <ul class="space-y-2">
                <li v-for="(detail, idx) in faq.details" 
                    :key="idx"
                    class="flex items-start gap-2"
                >
                  <svg class="w-5 h-5 text-emerald-500 mt-0.5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <span>{{ detail }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-12 text-center">
        <p class="text-[hsl(220,15%,85%)] mb-4">Still have questions?</p>
        <button 
          class="btn-secondary"
          @click="showEmailForm"
        >
          Learn More
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import eventBus from '../eventBus';

export default {
  name: 'FaqSection',
  data() {
    return {
      faqs: [
        {
          question: "How does CarbonLedger integrate with existing systems?",
          answer: "CarbonLedger seamlessly integrates with your current energy management infrastructure through our enterprise-grade API and extensive partnership network.",
          details: [
            "Direct integration with major building management systems",
            "Support for standard protocols including Modbus, BACnet, and APIs",
            "Custom integration solutions for legacy systems",
            "Dedicated integration support team"
          ],
          isOpen: false
        },
        {
          question: "What security measures are in place to protect our data?",
          answer: "We maintain the highest level of security standards to protect your sensitive energy data and ensure compliance with industry regulations.",
          details: [
            "ISO 27001 certified infrastructure",
            "End-to-end encryption for all data transmission",
            "Regular third-party security audits",
            "GDPR and CCPA compliant"
          ],
          isOpen: false
        },
        {
          question: "How quickly can we implement CarbonLedger across our organization?",
          answer: "Implementation timelines vary based on your organization's size and requirements, but we typically achieve full deployment within 2-4 weeks.",
          details: [
            "Initial setup and configuration in 24-48 hours",
            "Phased rollout approach for minimal disruption",
            "Dedicated implementation manager",
            "Comprehensive staff training included"
          ],
          isOpen: false
        },
        {
          question: "What ROI can we expect from implementing CarbonLedger?",
          answer: "Our enterprise clients typically see significant returns within the first year of implementation through various optimization opportunities.",
          details: [
            "Average 25-40% reduction in energy costs",
            "Typical payback period of 12-18 months",
            "Reduced carbon tax exposure",
            "Automated reporting saves 50+ hours quarterly"
          ],
          isOpen: false
        },
        {
          question: "How does CarbonLedger support regulatory compliance?",
          answer: "Our platform automatically tracks and reports on key metrics required for various regulatory frameworks and sustainability initiatives.",
          details: [
            "Built-in templates for SECR and ESOS reporting",
            "Automated carbon footprint calculations",
            "Real-time compliance monitoring",
            "Regular updates to meet changing regulations"
          ],
          isOpen: false
        }
      ]
    }
  },
  methods: {
    toggleFaq(index) {
      this.faqs = this.faqs.map((faq, i) => ({
        ...faq,
        isOpen: i === index ? !faq.isOpen : false
      }));
    },
    showEmailForm() {
      eventBus.emit('show-email-form');
      document.querySelector('#app').scrollIntoView({ behavior: 'smooth' });
    }
  }
}
</script>

<style scoped>
.btn-secondary {
  @apply border-2 border-emerald-600 text-emerald-500 px-6 py-3 rounded-lg font-semibold 
         hover:bg-emerald-600/10 transition-all duration-300;
}
</style>