<template>
  <section id="features" class="py-24 relative">
    <!-- Background decorative element -->
    <div class="absolute inset-0 -z-10">
      <div class="absolute inset-y-0 right-0 w-1/2 bg-gradient-to-l from-[hsl(160,84%,39%,0.08)] to-transparent"></div>
      <div class="absolute inset-y-0 left-0 w-1/2 bg-gradient-to-r from-[hsl(160,84%,39%,0.08)] to-transparent"></div>
      <div class="absolute inset-0 bg-[hsl(160,84%,39%,0.03)]"></div>
    </div>

    <div class="container mx-auto px-4">
      <div class="text-center mb-20">
        <h2 class="text-3xl md:text-5xl font-bold text-[hsl(0,0%,100%)] mb-6">Smart Energy Management</h2>
        <p class="text-xl text-[hsl(220,15%,85%)] max-w-3xl mx-auto">
          Our comprehensive platform provides the tools and insights you need to optimize 
          your energy consumption, reduce costs, and meet sustainability goals.
        </p>
      </div>

      <div class="grid lg:grid-cols-3 gap-8 mb-20">
        <!-- Real-time Intelligence Card -->
        <div class="feature-card group relative p-8 rounded-2xl bg-[hsl(222,20%,12%,0.7)] border border-[hsl(160,84%,39%,0.15)] 
                    hover:border-[hsl(160,84%,39%,0.3)] hover:bg-[hsl(222,20%,12%,0.8)] transition-all duration-300">
          <div class="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[hsl(160,84%,39%,0.1)] via-transparent to-transparent 
                      rounded-tr-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
          
          <div class="relative">
            <div class="w-16 h-16 mb-6">
              <svg class="w-full h-full" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="100" cy="100" r="80" stroke="currentColor" stroke-width="4" class="text-emerald-500/10"/>
                <circle cx="100" cy="100" r="70" stroke="currentColor" stroke-width="3" class="text-emerald-500/20"/>
                <path d="M60,100 L90,100 L110,60 L130,130 L150,100 L180,100" 
                      stroke="currentColor" stroke-width="4" stroke-linecap="round" class="text-emerald-500"/>
                <circle cx="110" cy="60" r="4" fill="currentColor" class="text-emerald-500"/>
                <circle cx="130" cy="130" r="4" fill="currentColor" class="text-emerald-500"/>
                <g transform="translate(70,140)">
                  <rect width="60" height="20" rx="4" stroke="currentColor" stroke-width="2" class="text-emerald-500"/>
                  <line x1="10" y1="10" x2="50" y2="10" stroke="currentColor" stroke-width="2" class="text-emerald-500"/>
                </g>
              </svg>
            </div>
            
            <h3 class="text-2xl font-semibold text-[hsl(160,15%,95%)] mb-4">Real-Time Intelligence</h3>
            <p class="text-[hsl(220,15%,85%)] mb-6">Advanced analytics and monitoring that transform complex energy data into actionable insights.</p>
            
            <ul class="space-y-3">
              <li v-for="benefit in features[0].benefits" 
                  class="flex items-start gap-3 text-[hsl(220,15%,85%)]">
                <svg class="w-5 h-5 text-[hsl(160,84%,39%)] hover:text-[hsl(160,84%,45%)] mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>{{ benefit }}</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- Cost Optimization Card -->
        <div class="feature-card group relative p-8 rounded-2xl bg-[hsl(222,20%,12%,0.7)] border border-[hsl(160,84%,39%,0.15)] 
                    hover:border-[hsl(160,84%,39%,0.3)] hover:bg-[hsl(222,20%,12%,0.8)] transition-all duration-300">
          <div class="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[hsl(160,84%,39%,0.1)] via-transparent to-transparent 
                      rounded-tr-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
          
          <div class="relative">
            <div class="w-16 h-16 mb-6">
              <svg class="w-full h-full" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="100" cy="100" r="80" stroke="currentColor" stroke-width="4" class="text-emerald-500/10"/>
                <path d="M70,140 L70,80 L100,60 L130,80 L130,140" 
                      stroke="currentColor" stroke-width="4" stroke-linecap="round" class="text-emerald-500"/>
                <path d="M100,60 L100,140" stroke="currentColor" stroke-width="4" stroke-linecap="round" class="text-emerald-500"/>
                <text x="95" y="110" font-family="Arial" font-size="24" fill="currentColor" class="text-emerald-500">£</text>
                <path d="M80,100 C80,90 120,90 120,100" 
                      stroke="currentColor" stroke-width="3" stroke-linecap="round" class="text-emerald-500"/>
                <circle cx="120" cy="100" r="4" fill="currentColor" class="text-emerald-500"/>
              </svg>
            </div>
            
            <h3 class="text-2xl font-semibold text-[hsl(160,15%,95%)] mb-4">Cost Optimization</h3>
            <p class="text-[hsl(220,15%,85%)] mb-6">AI-powered tools that automatically identify savings opportunities and optimize energy usage.</p>
            
            <ul class="space-y-3">
              <li v-for="benefit in features[1].benefits" 
                  class="flex items-start gap-3 text-[hsl(220,15%,85%)]">
                <svg class="w-5 h-5 text-[hsl(160,84%,39%)] hover:text-[hsl(160,84%,45%)] mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>{{ benefit }}</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- Sustainability Suite Card -->
        <div class="feature-card group relative p-8 rounded-2xl bg-[hsl(222,20%,12%,0.7)] border border-[hsl(160,84%,39%,0.15)] 
                    hover:border-[hsl(160,84%,39%,0.3)] hover:bg-[hsl(222,20%,12%,0.8)] transition-all duration-300">
          <div class="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[hsl(160,84%,39%,0.1)] via-transparent to-transparent 
                      rounded-tr-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
          
          <div class="relative">
            <div class="w-16 h-16 mb-6">
              <svg class="w-full h-full" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="100" cy="100" r="80" stroke="currentColor" stroke-width="4" class="text-emerald-500/10"/>
                <path d="M100,60 C60,60 60,140 100,140 C140,140 140,60 100,60" 
                      stroke="currentColor" stroke-width="4" class="text-emerald-500"/>
                <path d="M100,80 C80,80 80,120 100,120 C120,120 120,80 100,80" 
                      fill="currentColor" class="text-emerald-500/20"/>
                <circle cx="100" cy="90" r="4" fill="currentColor" class="text-emerald-500"/>
                <path d="M85,130 L115,130" stroke="currentColor" stroke-width="3" stroke-linecap="round" class="text-emerald-500"/>
              </svg>
            </div>
            
            <h3 class="text-2xl font-semibold text-[hsl(160,15%,95%)] mb-4">Sustainability Suite</h3>
            <p class="text-[hsl(220,15%,85%)] mb-6">Comprehensive tools for tracking, reporting, and improving your environmental impact.</p>
            
            <ul class="space-y-3">
              <li v-for="benefit in features[2].benefits" 
                  class="flex items-start gap-3 text-[hsl(220,15%,85%)]">
                <svg class="w-5 h-5 text-[hsl(160,84%,39%)] hover:text-[hsl(160,84%,45%)] mt-1 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
                <span>{{ benefit }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Stats Section -->
      <div class="mt-20">
        <div class="grid grid-cols-1 md:grid-cols-4 gap-8 text-center">
          <div class="stat-card">
            <div class="text-4xl font-bold text-[hsl(160,84%,39%)] mb-2 [text-shadow:0_0_1px_rgba(0,0,0,0.1)]">30%</div>
            <p class="text-[hsl(220,15%,65%)]">Average Cost Savings</p>
          </div>
          <div class="stat-card">
            <div class="text-4xl font-bold text-[hsl(160,84%,39%)] mb-2">24/7</div>
            <p class="text-[hsl(220,15%,65%)]">Real-time Monitoring</p>
          </div>
          <div class="stat-card">
            <div class="text-4xl font-bold text-[hsl(160,84%,39%)] mb-2">15min</div>
            <p class="text-[hsl(220,15%,65%)]">Setup Time</p>
          </div>
          <div class="stat-card">
            <div class="text-4xl font-bold text-[hsl(160,84%,39%)] mb-2">99.9%</div>
            <p class="text-[hsl(220,15%,65%)]">Uptime Guarantee</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturesSection',
  data() {
    return {
      features: [
        {
          title: 'Real-Time Intelligence',
          description: 'Advanced analytics and monitoring that transform complex energy data into actionable insights.',
          benefits: [
            'Live energy consumption monitoring',
            'Predictive demand forecasting',
            'Automated anomaly detection',
            'Custom reporting dashboards'
          ]
        },
        {
          title: 'Cost Optimization',
          description: 'AI-powered tools that automatically identify savings opportunities and optimize energy usage.',
          benefits: [
            'Dynamic rate optimization',
            'Peak demand management',
            'ROI-focused recommendations',
            'Budget forecasting tools'
          ]
        },
        {
          title: 'Sustainability Suite',
          description: 'Comprehensive tools for tracking, reporting, and improving your environmental impact.',
          benefits: [
            'Carbon footprint tracking',
            'ESG reporting automation',
            'Renewable energy insights',
            'Compliance monitoring'
          ]
        }
      ],
      integrationPartners: [
        {
          name: 'Schneider Electric',
          system: 'EcoStruxure Platform'
        },
        {
          name: 'Siemens',
          system: 'Desigo CC System'
        },
        {
          name: 'Honeywell',
          system: 'Enterprise Buildings'
        },
        {
          name: 'Johnson Controls',
          system: 'OpenBlue Platform'
        }
      ]
    }
  }
}
</script>

<style scoped>
.feature-card {
  backdrop-filter: blur(8px);
}

.integration-card {
  backdrop-filter: blur(4px);
}
</style>