<template>
  <section class="hero-section relative overflow-hidden">
    <!-- Background elements -->
    <div class="absolute -z-10 inset-0">
      <div class="animate-pulse-slow absolute top-20 left-20 w-72 h-72 bg-[hsl(160,84%,39%)] rounded-full filter blur-3xl opacity-[0.15]"></div>
      <div class="animate-pulse-slow absolute bottom-20 right-20 w-72 h-72 bg-[hsl(160,84%,39%)] rounded-full filter blur-3xl opacity-[0.15]"></div>
    </div>
    
    <div class="container mx-auto px-4 py-20">
      <div class="max-w-4xl mx-auto text-center mb-16">
        <h1 class="text-4xl md:text-6xl font-bold mb-6 text-[hsl(0,0%,100%)]">
          Simplify Your Carbon Impact
        </h1>
        <p class="text-xl mb-8 text-[hsl(220,15%,85%)]">
          Join forward-thinking businesses reducing their carbon footprint through smart energy choices
        </p>

        <button 
          class="btn-primary bg-[hsl(160,84%,39%)] text-white px-8 py-3 rounded-lg font-semibold 
                 hover:bg-[hsl(160,84%,45%)] active:bg-[hsl(160,84%,35%)] transition-all"
          @click="toggleEmailForm"
        >
          Get Started
        </button>
      </div>

      <!-- Key metrics cards -->
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
        <div class="bg-[hsl(222,20%,12%,0.7)] backdrop-blur-sm rounded-xl p-6 border border-[hsl(160,84%,39%,0.15)]
                    hover:border-[hsl(160,84%,39%,0.3)] hover:bg-[hsl(222,20%,12%,0.8)] transition-all duration-300">
          <div class="flex items-center">
            <div class="p-3 bg-[hsl(160,84%,39%,0.1)] rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-[hsl(160,84%,39%)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
              </svg>
            </div>
            <div class="ml-4">
              <h3 class="text-lg font-medium text-[hsl(160,15%,95%)]">Carbon Impact</h3>
              <div class="mt-1">
                <p class="text-3xl font-semibold text-[hsl(160,84%,39%)]">27%</p>
                <p class="text-sm text-[hsl(220,15%,65%)]">Average reduction achieved</p>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-[hsl(222,20%,12%,0.7)] backdrop-blur-sm rounded-xl p-6 border border-[hsl(160,84%,39%,0.15)]
                    hover:border-[hsl(160,84%,39%,0.3)] hover:bg-[hsl(222,20%,12%,0.8)] transition-all duration-300">
          <div class="flex items-center">
            <div class="p-3 bg-[hsl(160,84%,39%,0.1)] rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-[hsl(160,84%,39%)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
              </svg>
            </div>
            <div class="ml-4">
              <h3 class="text-lg font-medium text-[hsl(160,15%,95%)]">Clean Energy</h3>
              <div class="mt-1">
                <p class="text-3xl font-semibold text-[hsl(160,84%,39%)]">62%</p>
                <p class="text-sm text-[hsl(220,15%,65%)]">Current renewable mix</p>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-[hsl(222,20%,12%,0.7)] backdrop-blur-sm rounded-xl p-6 border border-[hsl(160,84%,39%,0.15)]
                    hover:border-[hsl(160,84%,39%,0.3)] hover:bg-[hsl(222,20%,12%,0.8)] transition-all duration-300">
          <div class="flex items-center">
            <div class="p-3 bg-[hsl(160,84%,39%,0.1)] rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-[hsl(160,84%,39%)]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>
            </div>
            <div class="ml-4">
              <h3 class="text-lg font-medium text-[hsl(160,15%,95%)]">Network</h3>
              <div class="mt-1">
                <p class="text-3xl font-semibold text-[hsl(160,84%,39%)]">50+</p>
                <p class="text-sm text-[hsl(220,15%,65%)]">Businesses onboard</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Email form -->
      <transition name="fade">
        <div v-show="showEmailForm" class="email-form-container mt-12">
          <form @submit.prevent="submitEmail" 
                class="email-form max-w-md mx-auto flex bg-[hsl(222,20%,12%,0.7)] p-2 rounded-lg border border-[hsl(160,84%,39%,0.15)]">
            <input 
              type="email" 
              v-model="email" 
              placeholder="Enter your work email"
              class="email-input flex-1 bg-[hsl(222,20%,16%)] border border-[hsl(160,84%,39%,0.15)] rounded-l-lg px-4 py-2 
                     text-[hsl(220,15%,85%)] focus:outline-none focus:border-[hsl(160,84%,39%)]"
              required
            />
            <button 
              type="submit" 
              class="submit-btn bg-[hsl(160,84%,39%)] text-white px-6 py-2 rounded-r-lg 
                     hover:bg-[hsl(160,84%,45%)] active:bg-[hsl(160,84%,35%)] transition-colors"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? 'Sending...' : 'Get Started' }}
            </button>
          </form>
          <p v-if="submitStatus" 
             class="status-message mt-2 text-center" 
             :class="{ 
               'text-[hsl(160,84%,39%)]': !submitStatus.includes('wrong'),
               'text-[hsl(355,85%,55%)]': submitStatus.includes('wrong')
             }">
            {{ submitStatus }}
          </p>
        </div>
      </transition>
    </div>
  </section>
</template>

<script>
import eventBus from '../eventBus';

export default {
  name: 'HeroSection',
  data() {
    return {
      showEmailForm: false,
      email: '',
      submitStatus: '',
      isSubmitting: false
    }
  },
  methods: {
    toggleEmailForm() {
      this.showEmailForm = true;
      this.$nextTick(() => {
        const emailInput = this.$el.querySelector('.email-input');
        if (emailInput) emailInput.focus();
      });
    },
    async submitEmail() {
      if (this.isSubmitting) return;
      
      this.isSubmitting = true;
      this.submitStatus = '';
      
      try {
        const apiUrl = window.location.protocol === 'https:' 
          ? '/api/storeEmail'  // Keep as-is for production
          : 'http://localhost:8081/api/storeEmail';  // Direct to worker in development
        console.log('Submitting to:', apiUrl);

        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: this.email,
            timestamp: new Date().toISOString()
          })
        });
        
        console.log('Response status:', response.status);
        const responseText = await response.text();
        console.log('Response text:', responseText);
        
        let data;
        try {
          data = JSON.parse(responseText);
        } catch (parseError) {
          console.error('Failed to parse response as JSON:', parseError);
          throw new Error(`Server returned invalid JSON. Status: ${response.status}`);
        }
        
        if (!response.ok) {
          throw new Error(data.error || `HTTP error! status: ${response.status}`);
        }
        
        if (data.success) {
          this.submitStatus = 'Thanks! Our team will contact you shortly.';
          this.email = '';
          setTimeout(() => {
            this.showEmailForm = false;
            this.submitStatus = '';
          }, 3000);
        } else {
          throw new Error(data.error || 'Failed to submit');
        }
      } catch (error) {
        console.error('Failed to store email:', error);
        this.submitStatus = error.message === 'Failed to fetch' 
          ? 'Network error. Please check your connection and try again.'
          : `Error: ${error.message || 'Something went wrong. Please try again.'}`;
      } finally {
        this.isSubmitting = false;
      }
    }
  },
  mounted() {
    // Listen for show-email-form event using event bus
    eventBus.on('show-email-form', () => {
      this.showEmailForm = true;
      // Focus the email input after a short delay to allow for smooth scroll
      setTimeout(() => {
        const emailInput = this.$el.querySelector('.email-input');
        if (emailInput) emailInput.focus();
      }, 800);
    });
  },
  beforeUnmount() { // Changed from beforeDestroy to beforeUnmount for Vue 3
    // Clean up event listener
    eventBus.off('show-email-form');
  }
}
</script>

<style scoped>
.animate-pulse-slow {
  animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.15;
  }
  50% {
    opacity: 0.1;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>